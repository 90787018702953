import { ExcalidrawNodeData } from '@udecode/plate-excalidraw';

export const initialDataExcalidraw: ExcalidrawNodeData = {
  data: {
    elements: [
      {
        type: 'rectangle',
        version: 141,
        versionNonce: 361174001,
        isDeleted: false,
        id: 'oDVXy8D6rom3H1-LLH2-f',
        fillStyle: 'hachure',
        strokeWidth: 1,
        strokeStyle: 'solid',
        roughness: 1,
        opacity: 100,
        angle: 0,
        x: 100.50390625,
        y: 93.67578125,
        strokeColor: '#000000',
        backgroundColor: 'transparent',
        width: 186.47265625,
        height: 141.9765625,
        seed: 1968410350,
        groupIds: [],
      },
      {
        id: '-xMIs_0jIFqvpx-R9UnaG',
        type: 'ellipse',
        x: 300.5703125,
        y: 190.69140625,
        width: 198.21875,
        height: 129.51171875,
        angle: 0,
        strokeColor: '#000000',
        backgroundColor: 'transparent',
        fillStyle: 'hachure',
        strokeWidth: 1,
        strokeStyle: 'solid',
        roughness: 1,
        opacity: 100,
        groupIds: [],
        seed: 957947807,
        version: 47,
        versionNonce: 1128618623,
        isDeleted: false,
      },
    ],
    state: { viewBackgroundColor: '#AFEEEE', currentItemFontFamily: 1 },
  },
};
