import { useState,useEffect } from "react";
import { Card, Button, Timeline, Dropdown } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import "./index.css";

// antd 组件
import { WaterMark } from "@ant-design/pro-layout";

import {
  createPlateUI,
  HeadingToolbar,
  MentionCombobox,
  Plate,
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  createComboboxPlugin,
  createMentionPlugin,
  createIndentPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createDeserializeMdPlugin,
  createDeserializeCsvPlugin,
  createNormalizeTypesPlugin,
  createFontSizePlugin,
  createHorizontalRulePlugin,
  createPlugins,
  createDeserializeDocxPlugin,
  createJuicePlugin,
  usePlateActions,
  usePlateEditorRef,
  deserializeHtml,
  plateStore,
} from '@udecode/plate'

import { createFilePlugin } from "./plugin/file";
import {
  createExcalidrawPlugin,
  ELEMENT_EXCALIDRAW,
  ExcalidrawElement,
} from '@udecode/plate-excalidraw'
import { MarkBallonToolbar, ToolbarButtons } from './config/components/Toolbars'
import { withStyledPlaceHolders } from './config/components/withStyledPlaceHolders'
import { withStyledDraggables } from './config/components/withStyledDraggables'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { MENTIONABLES } from './config/mentionables'
import { CONFIG } from './config/config'
import { VALUES } from './config/values/values'



const isJSON = (str:any) => {
  if (typeof str == 'string') {
      try {
          var obj=JSON.parse(str);
          if(typeof obj == 'object' && obj ){
              return true;
          }else{
              return false;
          }

      } catch(e) {
          return false;
      }
  }
}


const EditorX = (props: any) => {
  let components = createPlateUI({
    [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
    // customize your components by plugin key
  });
  components = withStyledPlaceHolders(components);
  components = withStyledDraggables(components)
  const [initProps, setInitProps] = useState(props)

    const plugins = createPlugins(
      [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createTodoListPlugin(),
        createHeadingPlugin(),
        createImagePlugin({ props: { caption: { disabled: true }}, options: {uploadImage: initProps.uploadImage} }),
        createFilePlugin({options: {uploadFile: initProps.uploadFile}}),
        createHorizontalRulePlugin(),
        createLinkPlugin(),
        createListPlugin(),
        createTablePlugin(),
        createMediaEmbedPlugin(),
        createExcalidrawPlugin(),
        createCodeBlockPlugin(),
        createAlignPlugin(CONFIG.align),
        createBoldPlugin(),
        createCodePlugin(),
        createItalicPlugin(),
        createHighlightPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        createSubscriptPlugin(),
        createSuperscriptPlugin(),
        createFontColorPlugin(),
        createFontBackgroundColorPlugin(),
        createFontSizePlugin(),
        createKbdPlugin(),
        createNodeIdPlugin(),
        createDndPlugin(),
        createIndentPlugin(CONFIG.indent),
        createAutoformatPlugin(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
        createComboboxPlugin(),
        createMentionPlugin(),
        createDeserializeMdPlugin(),
        createDeserializeCsvPlugin(),
        createDeserializeDocxPlugin(),
        createJuicePlugin(),
      ],
      {
        components,
      }
    )


  const [fullscreen, setFullscreen] = useState(false);
  const action = usePlateActions()
  const plateEditor = usePlateEditorRef();


  let initialValue = [
    {
      id: 10086,
      type: 'p',
      children: [{ text: '' }],
    },
    {
      id: 10087,
      type: 'p',
      children: [{ text: '' }],
    }
  ]

  // TODO 这里的代码有问题
  
  useEffect(() => {
    const {id, initData} =initProps
    if (id === props.id) {
      if (plateEditor && initData) {
        if (isJSON(initData)) 
        {
          action.resetEditor()
          initialValue = JSON.parse(initData)
          action.setValue(initialValue, id)
          setInitProps({...initProps, initData: ''})
          return;
        } else {
            initialValue= deserializeHtml(plateEditor, {element: initData})
            setInitProps( {...initProps, initData: JSON.stringify(initialValue)})
        }
      }
    } else {
      setInitProps(props)
       
    }
  }, [props, initProps.initData, plateEditor])



  return (
    <WaterMark
      content={initProps.waterMark}
      fontColor="rgba(90,187,178,.19)"
      fontSize="16"
    >

      <DndProvider backend={HTML5Backend}>
      <Plate
        id={initProps.id}
        editableProps={{ ...CONFIG.editableProps, readOnly: initProps.readOnly }}
        plugins={plugins}
        initialValue={initialValue}
        renderEditable={(editable) => {
          return (
            <div className="card-wrap">
              <Card
                style={Object.assign(
                  {
                    maxWidth: fullscreen ? "calc(100% - 30px)" : "950px",
                    margin: "0 auto",
                    marginTop: "70px",
                    border: 0,
                    boxShadow: initProps.readOnly
                      ? ""
                      : "0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)",
                  },
                  props.editableStyle || {}
                )}
              >
                {editable}
              </Card>
            </div>
          );
        }}
      >
        <HeadingToolbar style={props.headingToolbarStyle}>
          {!initProps.readOnly ? (
            <>
               <ToolbarButtons />
              <Button
                type="primary"
                size="small"
                style={{ position: "absolute", right: 70 }}
                onClick={(event) => {
                  setInitProps({...initProps, readOnly: true});
                  const value:any = plateStore.getState()[initProps.id].value
                  props.onSave(JSON.stringify(value));
                }}
              >
                保存
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              size="small"
              style={{ position: "absolute", right: 70 }}
              onClick={(e) => {
                setInitProps({...initProps, readOnly: false});
              }}
            >
              编辑
            </Button>
          )}
          <Button
            size="small"
            icon={
              fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />
            }
            style={{ position: "absolute", right: 40 }}
            onClick={(e) => {
              setFullscreen(!fullscreen);
            }}
          ></Button>
        </HeadingToolbar>

        <MarkBallonToolbar />

        {/* <MentionCombobox items={CONFIG.mentionItems} />
        <MentionCombobox items={CONFIG.mentionItems} pluginKey="mention2" /> */}
      </Plate>
      </DndProvider>
    </WaterMark>
  );
};

export default EditorX;
