import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import EditorX from './editorX/editorX';
import reportWebVitals from './reportWebVitals';
import { useEffect } from 'react';
import { VALUES } from "./editorX/config/values/values";


const props = {
  id: 'maidddn2',
  // initData: '',
  // initData : '[{"id":10086,"type":"p","children":[{"text":""}]},{"id":1638545641182,"type":"p","children":[{"text":"2222123123"}]}]',
  initData:'[{"id":10086,"type":"p","children":[{"text":"123123123"}]},{"id":10087,"type":"h1","children":[{"text":"111111"}]}]',
  uploadFile:(dataurl:any) => {
    console.log(dataurl)
  },

  onSave: (data:any) => {
    console.log(data)
  },

  readOnly: false,
  waterMark: 'Septest sensetime',

  headingToolbarStyle: {
    background: "#fff",
    padding: "14px 120px",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 50,
    minWidth: "850px",
    boxSizing: "border-box",
    justifyContent: "center",
  },
  editableStyle: {
    marginTop: '100px'
  }
}

const prop1 = {
  id: '111111',
  initData: '<p>11111111111</p><h1>11111111111</h1>',
  uploadFile:(dataurl:any) => {
    console.log(dataurl)
  },

  onSave: (data:any) => {
    console.log(data)
  },

  readOnly: true,
  waterMark: 'Septest sensetime',

  headingToolbarStyle: {
    background: "#fff",
    padding: "14px 120px",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 50,
    minWidth: "850px",
    boxSizing: "border-box",
    justifyContent: "center",
  },
  editableStyle: {
    marginTop: '100px',
  }
}



const prop2 = {
  id: '222222',
  // initData: '',
  initData : '[{"id":10086,"type":"p","children":[{"text":"2222222222222"}]},{"id":1638545641182,"type":"h1","children":[{"text":"222222222222"}]}]',
  // initData:'[{"id":10086,"type":"p","children":[{"text":"123123123"}]},{"id":10087,"type":"h1","children":[{"text":"111111"}]}]',
  uploadFile:(dataurl:any) => {
    console.log(dataurl)
  },
  onSave: (data:any) => {
    console.log(data)
  },

  readOnly: false,
  waterMark: 'Septest sensetime',

  headingToolbarStyle: {
    background: "#fff",
    padding: "14px 120px",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 50,
    minWidth: "850px",
    boxSizing: "border-box",
    justifyContent: "center",
  },
  editableStyle: {
    marginTop: '100px'
  }
}

const playground = {
  id: 'playground',
  initData: JSON.stringify(VALUES.playground),
  uploadFile:(dataurl:any) => {
    console.log(dataurl)
  },

  onSave: (data:any) => {
    console.log(data)
  },

  readOnly: false,
  waterMark: 'Septest sensetime',

  headingToolbarStyle: {
    background: "#fff",
    padding: "14px 120px",
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 50,
    minWidth: "900px",
    boxSizing: "border-box",
    justifyContent: "center",
  },
  editableStyle: {
    marginTop: '100px'
  }
}




const MutiEditorX = (props:any) => {
const [initProps, setInitProps] = useState(props)
  return <div>
    <EditorX {...initProps} />
    {/* <button onClick={() => {setInitProps(prop1)}}>one</button>
    <button onClick={() => {setInitProps(prop2)}}>tow</button>
    <button onClick={() => {setInitProps(playground)}}>playground</button> */}
  </div>
}

ReactDOM.render(
  <React.StrictMode>
    <MutiEditorX {...playground} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
