/** @jsxRuntime classic */
/** @jsx jsx */
import { ELEMENT_HR, TDescendant } from '@udecode/plate';
import { createText } from '@udecode/plate-test-utils';
import * as faker from 'faker/locale/zh_CN';
import { initialDataExcalidraw } from './initialDataExcalidraw';
import { createList, getNodesWithRandomId } from './utils';
import { createHyperscript } from 'slate-hyperscript';

const ELEMENT_FILE = 'file';
const ELEMENT_ALIGN_RIGHT = 'align_right';
const ELEMENT_ALIGN_CENTER = 'align_center';
const ELEMENT_ALIGN_JUSTIFY = 'align_justify';
const ELEMENT_H1 = 'h1';
const ELEMENT_H2 = 'h2';
const ELEMENT_H3 = 'h3';
const ELEMENT_H4 = 'h4';
const ELEMENT_H5 = 'h5';
const ELEMENT_H6 = 'h6';
const ELEMENT_IMAGE = 'img';
const ELEMENT_LI = 'li';
const ELEMENT_LINK = 'a';
const ELEMENT_MEDIA_EMBED = 'media_embed';
const ELEMENT_MENTION = 'mention';
const ELEMENT_OL = 'ol';
const ELEMENT_PARAGRAPH = 'p';
const ELEMENT_TABLE = 'table';
const ELEMENT_TD = 'td';
const ELEMENT_TH = 'th';
const ELEMENT_TODO_LI = 'action_item';
const ELEMENT_TR = 'tr';
const ELEMENT_UL = 'ul';
const ELEMENT_BLOCKQUOTE = 'blockquote';
const ELEMENT_CODE_BLOCK = 'code_block';
const ELEMENT_CODE_LINE = 'code_line';
const ELEMENT_LIC = 'lic';
const ELEMENT_EXCALIDRAW = 'excalidraw';
const voidChildren = [{
  text: ''
}];


const jsx = createHyperscript({
  elements: {
    hfile: {
      type: ELEMENT_FILE
    },
    ha: {
      type: ELEMENT_LINK
    },
    hblockquote: {
      type: ELEMENT_BLOCKQUOTE
    },
    hcenter: {
      type: ELEMENT_ALIGN_CENTER
    },
    hcodeblock: {
      type: ELEMENT_CODE_BLOCK
    },
    hcodeline: {
      type: ELEMENT_CODE_LINE
    },
    hexcalidraw: {
      type: ELEMENT_EXCALIDRAW
    },
    hh1: {
      type: ELEMENT_H1
    },
    hh2: {
      type: ELEMENT_H2
    },
    hh3: {
      type: ELEMENT_H3
    },
    hh4: {
      type: ELEMENT_H4
    },
    hh5: {
      type: ELEMENT_H5
    },
    hh6: {
      type: ELEMENT_H6
    },
    himg: {
      type: ELEMENT_IMAGE,
      children: voidChildren
    },
    hjustify: {
      type: ELEMENT_ALIGN_JUSTIFY
    },
    hli: {
      type: ELEMENT_LI
    },
    hmention: {
      type: ELEMENT_MENTION,
      children: voidChildren
    },
    hmediaembed: {
      type: ELEMENT_MEDIA_EMBED,
      children: voidChildren
    },
    hol: {
      type: ELEMENT_OL
    },
    hp: {
      type: ELEMENT_PARAGRAPH
    },
    hright: {
      type: ELEMENT_ALIGN_RIGHT
    },
    htable: {
      type: ELEMENT_TABLE
    },
    htd: {
      type: ELEMENT_TD
    },
    hth: {
      type: ELEMENT_TH
    },
    htodoli: {
      type: ELEMENT_TODO_LI
    },
    htr: {
      type: ELEMENT_TR
    },
    hul: {
      type: ELEMENT_UL
    },
    hdefault: {
      type: ELEMENT_PARAGRAPH
    },
    hlic: {
      type: ELEMENT_LIC
    }
  },
  creators: {
    htext: createText
  }
});



const file: any = (
  <fragment>
     <hh1>📁文件资源</hh1>
    <hfile name="个人预算.xlsx" url="http://10.9.242.42:18181/g1/M00/01/3D/CvQAVmGuzcWATmIlAABnnaduqas98.xlsx"><htext />
      </hfile>
  </fragment>
);



const align: any = (
  <fragment>
    <hh1 align="right">向右对齐</hh1>
    <hp align="right">
      此文本向右对齐 -- 多年以后，当面对行刑队的时候。奥雷良诺·布恩迪亚上校一定会想起父亲带他去看冰块的那个遥远的下午。
    </hp>
    <hh2 align="center">居中对齐</hh2>
    <hp align="center">
      此文本居中对齐 -- 多年以后，当面对行刑队的时候。奥雷良诺·布恩迪亚上校一定会想起父亲带他去看冰块的那个遥远的下午。
    </hp>
    <hh2 align="left">铺满对齐</hh2>
    <hp align="justify">
      此块文本是铺满的 --  多年以后，当面对行刑队的时候。奥雷良诺·布恩迪亚上校一定会想起父亲带他去看冰块的那个遥远的下午。
    </hp>
  </fragment>
);

const lineHeight: any = (
<fragment>
    <hh1>行高</hh1>
    <hp>此块文本具有默认行高 --  多年以后，当面对行刑队的时候。奥雷良诺·布恩迪亚上校一定会想起父亲带他去看冰块的那个遥远的下午。</hp>
    <hp lineHeight={2}>
      此块文本的行高稍大 -- 多年以后，当面对行刑队的时候。奥雷良诺·布恩迪亚上校一定会想起父亲带他去看冰块的那个遥远的下午。
    </hp>
 
  </fragment>
);

const indent: any = (
  <fragment>
    <hh1>更改块缩进</hh1>
    <hp indent={1}>
      使用工具栏按钮控制特定块的缩进。你
      可以使用这些工具突出显示重要信息，
      传达层次结构或只是给您的内容一些空间。.
    </hp>
    <hp indent={2}>
     例如，这一段看起来像是属于上一段.
    </hp>
  </fragment>
);

const indentList: any = (
  <fragment>
  <hh1>现代载人飞船</hh1>
  <hp>List:</hp>
  <hp indent={1} listStyleType="upper-roman">
    Soyuz (Soviet/Russian)
  </hp>
  <hp indent={2} listStyleType="decimal">
    Early stage (all retired)
  </hp>
  <hp indent={3} listStyleType="decimal">
    7K-OK
  </hp>
  <hp indent={3} listStyleType="decimal">
    7KT-OK
  </hp>
  <hp indent={2} listStyleType="decimal">
    Soyuz T (retired)
  </hp>
  <hp indent={2} listStyleType="decimal">
    Soyuz TM (retired)
  </hp>
  <hp indent={1} listStyleType="upper-roman">
    STS orbiter (American; all retired)
  </hp>
  <hp indent={2} listStyleType="decimal">
    Columbia
  </hp>
  <hp indent={2} listStyleType="decimal">
    Challenger
  </hp>

  <hp indent={1} listStyleType="upper-roman">
    空格X Crew Dragon (American)
  </hp>
  <hp indent={1} listStyleType="upper-roman">
    Shenzhou (Chinese)
  </hp>
</fragment>
);

const empty: any = (
  <fragment>
    <hp>
      <htext />
    </hp>
  </fragment>
);

const placeholder: any = (
  <fragment>
    <hh1>
      <htext />
    </hh1>
    <hp>
      <htext />
    </hp>
  </fragment>
);

const horizontalRule: any = (
  <fragment>
    <hp>这是一段文本</hp>
    <element type={ELEMENT_HR}>
      <htext />
    </element>
    <hp>这是另外一段文本</hp>
    <element type={ELEMENT_HR}>
      <htext />
    </element>
    <hp>但这些段落之间是横向规则.</hp>
  </fragment>
);

const mediaEmbed: any = (
  <fragment>
    <hh2>🎥 多媒体</hh2>
 
    <hmediaembed url="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4">
      <htext />
    </hmediaembed>
  </fragment>
);

const excalidraw: any = (
  <fragment>
    <hh2>🎨 画版</hh2>
    <hexcalidraw {...initialDataExcalidraw}>
      <htext />
    </hexcalidraw>
  </fragment>
);

const forcedLayout: any = (
  <fragment>
  <hh1>
    SEP用户使用手册 beta
  </hh1>
  <hp>
    SEP文档使用了块编辑编译器，每一个段落都是一个块，它具有相同的格式，同时可以拖动不同块进行排序。
  </hp>
</fragment>
);

const balloonToolbar: any = (
  <fragment>
  <hp>
   此示例显示了如何使悬停菜单出现在您的上方
    内容，可用于制作文本 <htext bold>bold</htext>,{' '}
    <htext italic>italic</htext>, or anything else you might want to do!
  </hp>
  <hp>
    Try it out yourself! Just{' '}
    <htext bold>select any piece of text and the menu will appear</htext>.
  </hp>
  <hp>
    您可以启用和自定义每个工具栏按钮上的工具提示. Check
    Tippy.js documentation for more info!
  </hp>
</fragment>
);

const image: any = (
  <fragment>
    <hh2>📷 图片</hh2>
    <hp>
     支持添加复制拖入
    </hp>
    <himg url="https://source.unsplash.com/kFrdX5IeQzI" width="75%">
      <htext />
    </himg>

  </fragment>
);


const link: any = (
  <fragment>
    <hh2>🔗 链接</hh2>
    <hp>
     除了块节点，您还可以创建内联节点，例如{' '}
      <ha url="https://en.wikipedia.org/wiki/Hypertext">hyperlinks</ha>!
    </hp>
    <hp>
     此示例显示了正在运行的超链接。它具有两种添加方式
      链接。您可以通过上面的工具栏图标添加链接，或者如果您
      想知道一个小秘密，将 URL 复制到您的键盘并粘贴
      选择了一系列文本.
    </hp>
  </fragment>
);

const previewMd: any = (
  <fragment>
    <hh1>👀 Preview Markdown</hh1>
    <hp>
     Slate 足够灵活，可以添加可以基于文本格式化的 **decorations**
      在其内容上。比如这个编辑器有**Markdown**预览
      装饰它，使其_死_简单地制作一个编辑器
      内置 `Markdown` 预览.
    </hp>
    <hp>- List.</hp>
    <hp> Blockquote.</hp>
    <hp>---</hp>
    <hp>## Try it out!</hp>
    <hp>Try it out for yourself!</hp>
  </fragment>
);

const autoformat: any = (
  <fragment>
    <hh1>🏃‍♀️ 自动格式化</hh1>
    <hp>
     编辑器全面支持markdown语法，你可以使用markdown语法快速创建你的文档。
    </hp>
    <hp> (mark 规制):</hp>
    <hul>
      <hli>
        <hlic>
          输入 <htext code>**</htext> 或者 <htext code>__</htext> 在你文本的两边，设置你的文本为粗体
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>*</htext> 或者 <htext code>_</htext> 在你文本的两边，设置你的文本为斜体
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>`</htext> 在你文本的两边，设置你的文本为行内代码
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>~~</htext> 在你文本的两边，设置你的文本为下标
        </hlic>
      </hli>
   
    </hul>
    <hp>
      在段落的开始，尝试使用以下规则来创建你的块
    </hp>
    <hul>
      <hli>
        <hlic>
          输入 <htext code>*</htext>, <htext code>-</htext> 或者{' '}
          <htext code>+</htext> 再追加 <htext code>空格</htext> 来创建列表
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>1.</htext> 或者 <htext code>1)</htext> 再追加{' '}
          <htext code>空格</htext> 来创建有序列表
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>&gt;</htext> 再追加 <htext code>空格</htext> 来创建引用字段
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>```</htext> 来创建代码块
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>---</htext> 创建分割线
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>#</htext> 再追加 <htext code>空格</htext> 来创建 H1 标题.
        </hlic>
      </hli>
      <hli>
        <hlic>
          输入 <htext code>##</htext> 再追加 <htext code>空格</htext> 来创建 H2 标题.
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>###</htext> 再追加 <htext code>空格</htext> 来创建 H3 标题.
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>####</htext> 再追加 <htext code>空格</htext> 来创建 H4 标题.
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>#####</htext> 再追加 <htext code>空格</htext>{' '}
          来创建 H5 标题.
        </hlic>
      </hli>
      <hli>
        <hlic>
        输入 <htext code>######</htext> 再追加 <htext code>空格</htext>{' '}
          来创建 H6 标题.
        </hlic>
      </hli>
    </hul>
  </fragment>
);

const mentions: any = (
  <fragment>
    <hh2>💬 Mention</hh2>
    <hp>
      This example shows how you might implement a simple @-mentions feature
      that lets users autocomplete mentioning a user by their username. Which,
      in this case means Star Wars characters. The mentions are rendered as void
      inline elements inside the document.
    </hp>
    <hp>
      Try mentioning characters, like{' '}
      <hmention value="R2-D2">
        <htext />
      </hmention>{' '}
      or{' '}
      <hmention value="Mace Windu">
        <htext />
      </hmention>
      !
    </hp>
  </fragment>
);

const pasteHtml: any = (
  <fragment>
    <hh1>🍪 Deserialize HTML</hh1>
    <hp>
      By default, pasting content into a Slate editor will use the clipboard's{' '}
      <htext code>'text/plain'</htext> data. That's okay for some use cases, but
      sometimes you want users to be able to paste in content and have it
      maintain its formatting. To do this, your editor needs to handle{' '}
      <htext code>'text/html'</htext> data.
    </hp>
    <hp>This is an example of doing exactly that!</hp>
    <hp>
      Try it out for yourself! Copy and paste some rendered HTML rich text
      content (not the source code) from another site into this editor and it's
      formatting should be preserved.
    </hp>
  </fragment>
);

const pasteMd: any = (
  <fragment>
    <hh1>🍩 Deserialize Markdown</hh1>
    <hp>
      By default, pasting content into a Slate editor will use the clipboard's{' '}
      <htext code>'text/plain'</htext> data. That's okay for some use cases, but
      sometimes you want users to be able to paste in content and have it
      maintain its formatting. To do this, your editor needs to handle{' '}
      <htext code>'text/html'</htext> data.
    </hp>
    <hp>This is an example of doing exactly that!</hp>
    <hp>
      Try it out for yourself! Copy and paste Markdown content from{' '}
      <ha url="https://markdown-it.github.io/">
        https://markdown-it.github.io/
      </ha>
    </hp>
  </fragment>
);

const pasteAst: any = (
  <fragment>
    <hh2>✍️ Slate AST</hh2>
    <hul>
      <hli>
        <hlic>Bulleted list</hlic>
        <hul>
          <hli>
            <hlic>support</hlic>
            <hul>
              <hli>
                <hlic>a</hlic>
              </hli>
            </hul>
          </hli>
          <hli>
            <hlic>nesting</hlic>
            <hul>
              <hli>
                <hlic>b</hlic>
              </hli>
            </hul>
          </hli>
        </hul>
      </hli>
      <hli>
        <hlic>c</hlic>
      </hli>
    </hul>
    <hp>
      This example shows how you can handle copy/paste by handling slate ast
      trees.
    </hp>
  </fragment>
);

const plainText: any = (
  <fragment>
    <hp>
      This is editable plain text with react and history plugins, just like a
      textarea!
    </hp>
  </fragment>
);

const pasteCsv: any = (
  <fragment>
    <hp>
      This example shows how pasting from csv can get parsed into a table. Grab
      a CSV source and paste it below.
    </hp>
  </fragment>
);

const basicMarks: any = (
  <fragment>
    <hh1>💅 标记</hh1>
    <hh2>💧 基础 标记</hh2>
    <hp>
    基本标记包括文本格式，例如粗体、斜体、下划线、删除线、下标、上标和代码。
    </hp>
    <hp>
      <htext bold>这段文字是粗体的。</htext>
    </hp>
    <hp>
      <htext italic>这段文字是斜体的。</htext>
    </hp>
    <hp>
      <htext underline>这段文字有下划线。</htext>
    </hp>
    <hp>
      <htext bold italic underline>
      此文本为粗体、斜体和下划线。
      </htext>
    </hp>
    <hp>
      <htext strikethrough>这是一个有删除线文本。</htext>
    </hp>
    <hp>
      <htext code>这是一段代码。</htext>
    </hp>
  </fragment>
);

const font: any = (
  <fragment>
    <hp>
      <htext color="white" backgroundColor="black" fontSize="30px">
      此文本具有白色、黑色背景和自定义字体大小。
      </htext>
    </hp>
    <hp>
      <htext color="grey" backgroundColor="green">
      此文本具有用于文本和背景的自定义颜色。
      </htext>
    </hp>
    <hp>
    此文本具有 <htext backgroundColor="#dc3735"> </htext>
   
      <htext color="white" backgroundColor="#e2533a">
      多种
      </htext>
    
      <htext color="white" backgroundColor="#e96f40">
      字体
      </htext>
    
      <htext color="white" backgroundColor="#ef8a45">
      颜色
      </htext>
      和 <htext backgroundColor="#a58ce1">字体</htext>{' '}
      <htext backgroundColor="#99cc62">背景</htext>{' '}
      <htext backgroundColor="#e45260">颜色</htext>.
    </hp>
    <hp>
      <htext bold italic underline color="#f92672">
      此文本为粗体、斜体、下划线和彩色。
      </htext>
    </hp>
  </fragment>
);

const kbd: any = (
  <fragment>
    <hp>
      Press <htext kbd>⌘+B</htext> to mark selected text bold or{' '}
      <htext kbd>⌘+I</htext> to mark it italic.
    </hp>
  </fragment>
);

const marks = [...basicMarks, ...kbd];


const basicElements: any = (
  <fragment>
    {/* <hh1>🧱 Elements</hh1>
    <hh2>🔥 Basic Elements</hh2>
    <hp>These are the most common elements, known as blocks:</hp>
    <hh1>Heading 1</hh1>
    <hh2>Heading 2</hh2>
    <hh3>Heading 3</hh3>
    <hh4>Heading 4</hh4>
    <hh5>Heading 5</hh5>
    <hh6>Heading 6</hh6>
    <hblockquote>Blockquote</hblockquote> */}
    <hh1>这是代码块</hh1>
    <hcodeblock lang="javascript">
      <hcodeline>const a = 'Hello';</hcodeline>
      {/* <hcodeline>const b = 'World';</hcodeline> */}
    </hcodeblock>
  </fragment>
);

const list: any = (
  <fragment>
    <hh2>✍️ 列表</hh2>
    <hp>
      <htext />
    </hp>
    <hul>
      <hli>
        <hlic>无序列表</hlic>
        <hul>
          <hli>
            <hlic>a</hlic>
            <hul>
              <hli>
                <hlic>a-a</hlic>
              </hli>
            </hul>
          </hli>
          <hli>
            <hlic>b</hlic>
            <hul>
              <hli>
                <hlic>b-b</hlic>
              </hli>
            </hul>
          </hli>
        </hul>
      </hli>
      <hli>
        <hlic>c</hlic>
      </hli>
    </hul>
    <hol>
      <hli>
        <hlic>有序列表</hlic>
      </hli>
    </hol>
    <hp>
      代办清单（快捷键：opt+cmd+4）
    </hp>
    <htodoli checked>苹果</htodoli>
    <htodoli checked>香蕉</htodoli>
    <htodoli>萝卜</htodoli>
  </fragment>
);

const findReplace: any = (
  <fragment>
    <hp>
      This is editable text that you can search. As you search, it looks for
      matching strings of text, and adds <htext bold>decorations</htext> to them
      in realtime.
    </hp>
    <hp>Try it out for yourself by typing in the search box above!</hp>
  </fragment>
);

const createTable = (): any => (
  <fragment>
    <htable>
      <htr>
        <htd>
          <hp>
            <htext />
          </hp>
        </htd>
        <htd>
          <hp>
            <htext bold>人类</htext>
          </hp>
        </htd>
        <htd>
          <hp>
            <htext bold>狗</htext>
          </hp>
        </htd>
        <htd>
          <hp>
            <htext bold>猫</htext>
          </hp>
        </htd>
      </htr>
      <htr>
        <htd>
          <hp>
            <htext bold># 脚</htext>
          </hp>
        </htd>
        <htd>
          <hp>2</hp>
        </htd>
        <htd>
          <hp>4</hp>
        </htd>
        <htd>
          <hp>4</hp>
        </htd>
      </htr>
      <htr>
        <htd>
          <hp>
            <htext bold># 命</htext>
          </hp>
        </htd>
        <htd>
          <hp>1</hp>
        </htd>
        <htd>
          <hp>1</hp>
        </htd>
        <htd>
          <hp>9</hp>
        </htd>
      </htr>
    </htable>
  </fragment>
);

const createSpanningTable = (): any => (
  <fragment>
    <htable>
      <htr>
        <hth attributes={{ colspan: '2' }}>
          <hp>
            <htext bold>Heading</htext>
          </hp>
        </hth>
      </htr>
      <htr>
        <htd>
          <hp>
            <htext bold>Cell 1</htext>
          </hp>
        </htd>
        <htd>
          <hp>Cell 2</hp>
        </htd>
      </htr>
    </htable>
  </fragment>
);

const table: any = (
  <fragment>
    <hh2>🏓 表格beta</hh2>
    {createTable()}
    {/* <hp>
      This table is just a basic example of rendering a table, and it doesn't
      have fancy functionality. But you could augment it to add support for
      navigating with arrow keys, displaying table headers, adding column and
      rows, or even formulas if you wanted to get really crazy!
    </hp>
    {createSpanningTable()}
    <hp>
      This table is an example of rendering a table spanning multiple columns.
    </hp> */}
  </fragment>
);

const softBreak: any = (
  <fragment>
    <hh1>🍦 块内换行 ⇧⏎</hh1>
 
    <hblockquote>在这里尝试 ⇧⏎</hblockquote>
    <hcodeblock>
      <hcodeline>或者这里 ⇧⏎</hcodeline>
    </hcodeblock>
  </fragment>
);

const singleLine: any = (
  <fragment>
    <hp>You cannot type or paste text with multiple lines.</hp>
  </fragment>
);

const exitBreak: any = (
  <fragment>
    <hh1>⏎ 跳出当前块 ⏎</hh1>
    <hblockquote>在这里尝试 ⌘⏎</hblockquote>
    <hcodeblock>
      <hcodeline>或者在这段文字 ⌘⏎ 的中间</hcodeline>
    </hcodeblock>
    <hp>在表格里也是同样的规制</hp>
    {createTable()}
  </fragment>
);

const editableVoids: any = (
  <fragment>
    <hp>
      In addition to nodes that contain editable text, you can insert void
      nodes, which can also contain editable elements, inputs, or an entire
      other Slate editor.
    </hp>
    <element type="editable-void">
      <htext />
    </element>
    <hp>
      <htext />
    </hp>
  </fragment>
);

const iframe: any = (
  <fragment>
    <hp>
      In this example, the document gets rendered into a controlled{' '}
      <htext code>iframe</htext>. This is <htext italic>particularly</htext>{' '}
      useful, when you need to separate the styles for your editor contents from
      the ones addressing your UI.
    </hp>
    <hp>
      This also the only reliable method to preview any{' '}
      <htext bold>media queries</htext> in your CSS.
    </hp>
  </fragment>
);

const HEADINGS = 100;
const PARAGRAPHS = 7;

const createHugeDocument = () => {
  const hugeDocument: TDescendant[] = [];

  for (let h = 0; h < HEADINGS; h++) {
    hugeDocument.push(<hh1>{faker.lorem.sentence()}</hh1>);

    for (let p = 0; p < PARAGRAPHS; p++) {
      hugeDocument.push(<hp>{faker.lorem.paragraph()}</hp>);
    }
  }

  return hugeDocument;
};

const createMultiEditors = () => {
  const multiEditors: TDescendant[][] = [];

  for (let h = 0; h < 350; h++) {
    const multiEditor: TDescendant[] = [];
    multiEditor.push(<hh1>{faker.lorem.sentence()}</hh1>);

    for (let p = 0; p < 2; p++) {
      multiEditor.push(<hp>{faker.lorem.paragraph()}</hp>);
    }
    multiEditors.push(multiEditor);
  }

  return multiEditors;
};

const basicNodes = [...basicElements, ...basicMarks];

const playground: any = getNodesWithRandomId([
  ...forcedLayout,
  ...basicMarks,
  ...font,
  ...basicElements,
  ...align,
  ...lineHeight,
  ...indent,
  ...list,
  ...table,
  ...link,
  ...excalidraw,
  ...mediaEmbed,

  // ...mentions,
  ...image,
  ...file,
  ...autoformat,
  ...softBreak,
  ...exitBreak,
  // ...pasteHtml,
  // ...pasteMd,
  // ...pasteCsv,
  // ...pasteAst,
]);

export const VALUES: Record<string, any> = {
  align,
  autoformat,
  balloonToolbar,
  basicElements,
  basicMarks,
  basicNodes,
  createMultiEditors,
  createHugeDocument,
  createSpanningTable,
  createTable,
  editableVoids,
  empty,
  excalidraw,
  exitBreak,
  findReplace,
  font,
  forcedLayout,
  horizontalRule,
  iframe,
  image,
  indent,
  indentList,
  kbd,
  lineHeight,
  link,
  list,
  marks,
  mediaEmbed,
  mentions,
  pasteAst,
  pasteCsv,
  pasteHtml,
  pasteMd,
  placeholder,
  plainText,
  playground,
  previewMd,
  singleLine,
  softBreak,
  table,
};
