import { createStyles, getRootProps } from '@udecode/plate-styled-components';
import _styled, { css } from 'styled-components';
import React from 'react';

import { Button, Radio } from 'antd';
import { DownloadOutlined, EyeOutlined, FileTwoTone  } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { encode, decode } from 'js-base64'


const getLinkElementStyles = props => createStyles({
    prefixClassNames: 'LinkElement',
    ...props
  }, {
    root: css(["color:#0078d4;text-decoration:initial;:hover,:visited:hover{color:#004578;text-decoration:underline;}:visited{color:#0078d4;}"])
  });

  function _extends() {
    _extends = Object.assign || function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
  
      return target;
    };
  
    return _extends.apply(this, arguments);
  }


export const FileElement = props => {
    const {
      attributes,
      children,
      nodeProps,
      element
    } = props;


    const rootProps = getRootProps(props);
    const {
      root
    } = getLinkElementStyles(props);

    const attr = _extends({}, attributes, {
        className: root.className
      }, rootProps, nodeProps)


    return (<div {...attr}>
        {children}
        <Icon component={FileTwoTone } /> {element.name}
        <Button  icon={<DownloadOutlined />} size='small'>
        下载
    </Button>
        <Button   icon={<EyeOutlined/>} size='small' onClick={()=> {
          window.open('https://sepd.sensetime.com/preview' + '/onlinePreview?url=' + encodeURIComponent(encode(element.url)))
        }}>
            预览
        </Button>
        </div>)


    // return React.createElement(_StyledDiv, _extends({}, attributes, {
    //   href: element.url,
    //   className: root.className
    // }, rootProps, nodeProps, {
    //   $_css: root.css
    // }), children);
  };